import React from 'react'
import { useDimensions } from 'src/ui/dimensions'

import { Form as Desc } from './desk'
import { Form as Mob } from './mob'

import { Props } from './types'

export const Form: React.FC<Props> = (props) => {
  const { isMobile } = useDimensions()
  return isMobile ? <Mob {...props} /> : <Desc {...props} />
}
