import React from 'react'

import { useTheme, styled } from 'src/ui/theme'
import { breakpoints } from 'src/ui/dimensions'
import { Icons } from 'src/ui/icons'
import { Wrapper, HBox } from 'src/ui/layout'
import { Touchable } from 'src/ui/atoms'
import { Input, Select } from 'src/ui/molecules'
import { Body } from 'src/ui/typography'

import { Props } from './types'

const Grid = styled.div`
  display: grid;

  grid-template-columns: 310px 310px auto;
  gap: 24px;

  @media (max-width: ${breakpoints.tablet}px) {
    grid-template-columns: auto auto auto;
    gap: 16px;
  }
`

export const Form: React.FC<Props> = ({
  departments,
  regions,
  departmentValue,
  onDepartmentChange,
  onRegionChange,
  onSearchChange,
}) => {
  const theme = useTheme()

  const [searchValue, setSearchValue] = React.useState('')
  React.useEffect(() => {
    onSearchChange(searchValue)
  }, [searchValue])

  return (
    <Wrapper>
      <Grid>
        <div>
          <Body size="secondary">Местоположение</Body>
          <HBox height={8} />
          <Select
            value=""
            isMobile={false}
            placeholder={'Везде'}
            onChange={onRegionChange}
            options={regions}
          />
        </div>
        <div>
          <Body size="secondary">Направление</Body>
          <HBox height={8} />
          <Select
            value={departmentValue?.value || ''}
            isMobile={false}
            placeholder={'Все команды'}
            onChange={onDepartmentChange}
            options={departments.map((dep) => ({
              ...dep,
              label: dep.label,
            }))}
          />
        </div>
        <div>
          <Body size="secondary">Поиск вакансии</Body>
          <HBox height={8} />
          <Input
            name="search"
            placeholder="Название вакансии, команды, задачи"
            rightAdornment={
              <>
                <HBox height={10} />
                <Touchable
                  hoverColor={theme.colors.brand.primary}
                  onPress={() => onSearchChange(searchValue)}
                >
                  <Icons.Search />
                </Touchable>
              </>
            }
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      </Grid>
    </Wrapper>
  )
}
