import React from 'react'
import Img from 'gatsby-image'

import { useTheme, styled } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Adornments } from 'src/ui/atoms'
import { Body, Header as Title } from 'src/ui/typography'
import { decOfNums } from 'src/shared/utils'

import { Props } from './types'

const Illustrations = styled.div`
  width: 100%;
  overflow: hidden;
`

const Image = styled(Img)`
  object-fit: top right;
  width: 100%;
  height: 250px;
  z-index: 0;
  margin: 0 auto;
`

export const Header: React.FC<Props> = ({ vacancyCount, images }) => {
  const theme = useTheme()
  return (
    <>
      <Illustrations>
        <Image fluid={images['people']} />
      </Illustrations>
      <Wrapper>
        <HBox height={32} />
        <Title size="h4" as="h1">
          Вакансии в KODE
        </Title>
        <HBox height={8} />
        <Body color={theme.colors.black.secondary}>
          {vacancyCount} {decOfNums.formatOpenedVacancies(vacancyCount)}
        </Body>
      </Wrapper>
    </>
  )
}
