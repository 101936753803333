const findInOptions = (arr: TOption[], search: string) =>
  arr.find((item) => item.value === search)

export const uniqueOptions = (arr: TOption[]) => {
  let res: TOption[] = []

  arr.forEach((item) => {
    if (!findInOptions(res, item.value)) {
      res.push(item)
    }
  })

  return res
}
