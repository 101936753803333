import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { useTheme, styled } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import Img from 'gatsby-image'

import { Body, Header as Title } from 'src/ui/typography'
import { decOfNums } from 'src/shared/utils'

import { Props } from './types'

const VacanciesHeaderWrapper = styled.div`
  @media (max-width: ${breakpoints.tablet}px) {
    overflow: hidden;
  }
`

const Container = styled.div`
  position: absolute;
  left: 0;
  width: 100vw;
  height: 495px;
`

const Image = styled(Img)`
  position: absolute;
  top: 0;
  object-fit: top center;
  width: 100%;
  height: 100%;
  z-index: 0;
  margin: 0 auto;
`

const VacancyWrapper = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;
  padding: 36px 64px;
  background-color: white;
`

type SquareProps = {
  top: number
  right: number
  color: string
  size: number
}

const Square = styled.div<SquareProps>`
  position: absolute;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: ${({ color }) => color};
  right: ${({ right }) => right}px;
  top: ${({ top }) => top}px;
`

export const Header: React.FC<Props> = ({ vacancyCount, images }) => {
  const theme = useTheme()
  return (
    <VacanciesHeaderWrapper>
      <Wrapper>
        <Container>
          <Image fluid={images['people']} />
          <VacancyWrapper>
            <Title size="h2" as="h1">
              Вакансии в KODE
            </Title>
            <HBox height={8} />
            {vacancyCount > 0 && (
              <Body color={theme.colors.black.secondary}>
                {vacancyCount} {decOfNums.formatOpenedVacancies(vacancyCount)}
              </Body>
            )}
            <Square
              right={-80}
              top={0}
              color={theme.colors.brand.primary}
              size={80}
            />
            <Square
              right={0}
              top={-80}
              color={theme.colors.brand.primary}
              size={80}
            />
            <Square
              right={-40}
              top={-40}
              color={theme.colors.white.primary}
              size={40}
            />
          </VacancyWrapper>
        </Container>
      </Wrapper>
    </VacanciesHeaderWrapper>
  )
}
