export const sortItemsAsc = (path: string) => (a?: object, b?: object) => {
  // @ts-ignore
  a = a[path]
  // @ts-ignore
  b = b[path]

  if (!a && !b) {
    return 0
  }
  if (!a && b) {
    return 1
  }
  if (a && !b) {
    return -1
  }

  if (a && b && a > b) {
    return 1
  }

  if (a && b && a < b) {
    return -1
  }
  if (a && b && a === b) {
    return 0
  }

  return 0
}

export const sortItemsDesc = (path: string) => (a?: object, b?: object) => {
  // @ts-ignore
  a = a[path]
  // @ts-ignore
  b = b[path]

  if (!a && !b) {
    return 0
  }
  if (!a && b) {
    return -1
  }
  if (a && !b) {
    return 1
  }

  if (a && b && a > b) {
    return -1
  }

  if (a && b && a < b) {
    return 1
  }
  if (a && b && a === b) {
    return 0
  }
  return 0
}
