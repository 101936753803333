import { uniqueOptions, sortItemsAsc } from 'src/shared/utils'

export const getVacanciesByDepartments = (data: GatsbyTypes.DataQuery) => {
  if (data && data.strapi.vacancies) {
    let departments: (TOption & {
      order?: number
    })[] = []
    let regions: TOption[] = []
    let vacanciesByDepartments: TVacancyList = []

    let departmentsDescription: {
      [label: string]: string
    } = {}

    if (!data.strapi.vacancies) {
      return null
    }

    data.strapi.vacancies.forEach((vacancy) => {
      if (vacancy && vacancy.regions) {
        regions = [
          ...regions,
          ...vacancy.regions.map((region) => ({
            label: region?.name || '',
            value: region?.id?.toString() || '',
          })),
        ]
      }

      if (vacancy && vacancy.department) {
        departments.push({
          label: vacancy.department.name || '',
          value: vacancy.department.id?.toString() || '',
          order: vacancy.department.order,
        })

        if (vacancy.department.name && vacancy.department.description) {
          departmentsDescription[vacancy.department.name] =
            vacancy.department.description
        }
      }
    })

    departments = uniqueOptions(departments)
    departments = departments.sort(sortItemsAsc('order'))

    regions = uniqueOptions(regions)

    departments.forEach((department) => {
      const vacancies =
        data.strapi.vacancies &&
        data.strapi.vacancies
          .reduce<TVacancy[]>((acc, vacancy) => {
            if (
              vacancy &&
              vacancy.department?.id?.toString() === department.value
            ) {
              return (acc = [
                ...acc,
                {
                  id: vacancy.id?.toString(),
                  levels:
                    vacancy.levels?.map((level) => level?.name || '') || [],
                  conditions:
                    vacancy.conditions?.map(
                      (condition) => condition?.name || ''
                    ) || [],
                  regions: vacancy.regions?.map((reg) => reg?.name || '') || [],
                  title: vacancy.title || '',
                  excerpt: vacancy.excerpt || '',
                  link: '/career/' + vacancy.slug,
                  hot: vacancy.hot,
                  order: vacancy.order,
                },
              ])
            } else {
              return acc
            }
          }, [])
          .sort(sortItemsAsc('order'))

      if (
        !vacanciesByDepartments.find(
          (d) => d.departmentName === department.label
        )
      ) {
        vacanciesByDepartments.push({
          description: departmentsDescription[department.label] || '',
          departmentName: department.label,
          id: department.value,
          vacancyCount: vacancies ? vacancies.length : 0,
          vacancies: vacancies ? vacancies : [],
        })
      }
    })

    return { vacanciesByDepartments, departments, regions }
  } else {
    return null
  }
}
