import React from 'react'

import { HBox, Wrapper } from 'src/ui/layout'
import { Body } from 'src/ui/typography'

import { Accordion, Vacancy } from 'src/ui/organisms'

import { Props } from './types'

export const List: React.FC<Props> = ({ list }) => {
  return (
    <Wrapper>
      {list.map((item) => (
        <React.Fragment key={item.id}>
          <Accordion header={`${item.departmentName} • ${item.vacancyCount}`}>
            <>
              {item.vacancies.map((vacancy) => (
                <Vacancy
                  key={vacancy.id}
                  regions={vacancy.regions}
                  tags={vacancy.levels}
                  title={vacancy.title}
                  link={vacancy.link}
                >
                  <Body>{vacancy.excerpt}</Body>
                </Vacancy>
              ))}
            </>
          </Accordion>
          <HBox height={24} />
        </React.Fragment>
      ))}
    </Wrapper>
  )
}
