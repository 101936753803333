import React from 'react'

import { styled, useTheme } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Body, Header } from 'src/ui/typography'

import { Vacancy } from 'src/ui/organisms'
import { Props } from './types'

const Row = styled.div`
  display: grid;

  grid-template-columns: repeat(auto-fill, minmax(508px, 1fr));
  grid-template-rows: auto;
  row-gap: 43px;
  column-gap: 135px;

  @media (max-width: 1192px) {
    column-gap: 24px;
  }
`

const Title = styled(Body)`
  text-transform: uppercase;
`

export const List: React.FC<Props> = ({ list }) => {
  const theme = useTheme()

  if (list.length === 0) {
    return (
      <Wrapper>
        <HBox />
        <Header size="h5">Вакансии отсутсвуют</Header>
        <HBox />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {list.map((item) => (
        <React.Fragment key={item.id}>
          <Title
            weight="bold"
            color={theme.colors.black.secondary}
          >{`${item.departmentName} • ${item.vacancyCount}`}</Title>

          <HBox height={24} />
          <Row>
            <>
              {item.vacancies.map((vacancy) => (
                <Vacancy
                  key={vacancy.id}
                  regions={vacancy.regions}
                  tags={vacancy.levels}
                  title={vacancy.title}
                  link={vacancy.link}
                >
                  <Body as="span">{vacancy.excerpt}</Body>
                </Vacancy>
              ))}
            </>
          </Row>
          <HBox height={82} />
        </React.Fragment>
      ))}
    </Wrapper>
  )
}
