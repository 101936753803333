import React from 'react'

import { Icons } from 'src/ui/icons'
import { Wrapper, HBox } from 'src/ui/layout'
import { Touchable } from 'src/ui/atoms'
import { Input, Select } from 'src/ui/molecules'
import { Body } from 'src/ui/typography'
import { useTheme } from 'src/ui/theme'

import { Props } from './types'

export const Form: React.FC<Props> = ({
  departments,
  regions,
  departmentValue,
  onDepartmentChange,
  onRegionChange,
  onSearchChange,
}) => {
  const theme = useTheme()

  const [searchValue, setSearchValue] = React.useState('')
  React.useEffect(() => {
    onSearchChange(searchValue)
  }, [searchValue])

  return (
    <Wrapper>
      <Body size="secondary">Местоположение</Body>
      <HBox height={8} />
      <Select
        value=""
        isMobile={true}
        placeholder={'Везде'}
        onChange={onRegionChange}
        options={regions}
      />
      <HBox height={12} />
      <Body size="secondary">Направление</Body>
      <HBox height={8} />
      <Select
        value={departmentValue?.value || ''}
        isMobile={true}
        placeholder={'Выберите команду'}
        onChange={onDepartmentChange}
        options={departments.map((dep) => ({
          ...dep,
          label: dep.label,
        }))}
      />
      <HBox height={12} />
      <Body size="secondary">Поиск вакансии</Body>
      <HBox height={8} />
      <Input
        name="search"
        placeholder="Вакансия"
        rightAdornment={
          <>
            <HBox height={10} />
            <Touchable
              hoverColor={theme.colors.brand.primary}
              onPress={() => onSearchChange(searchValue)}
            >
              <Icons.Search />
            </Touchable>
          </>
        }
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </Wrapper>
  )
}
