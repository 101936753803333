import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { HBox } from 'src/ui/layout'
import { RootLayout } from 'src/layouts'
import { Header, Form, List } from 'src/features/vacancies'
import { Meta } from 'src/features/seo'

import { graphql, useStaticQuery, PageProps } from 'gatsby'

import { getVacanciesByDepartments } from 'src/shared/selectors'

type Props = PageProps

const Hero = styled.div<{ isMobile: boolean }>`
  position: relative;
  height: ${({ isMobile }) => (isMobile ? 'auto' : '495px')};
`

const Careers: React.FC<Props> = ({ location }) => {
  const { isMobile } = useDimensions()

  const query = useStaticQuery<GatsbyTypes.DataQuery>(graphql`
    query Data {
      allFile(filter: { relativePath: { regex: "/career/i" } }) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              fluid(maxWidth: 1900) {
                srcSet
                base64
                src
                aspectRatio
              }
            }
          }
        }
      }
      strapi {
        vacancies(where: { status: "publish" }) {
          id
          order
          slug
          title
          hot
          regions {
            name
            id
          }
          excerpt
          department {
            name
            id
            description
            order
          }
          conditions {
            name
            id
          }
          levels {
            id
            name
          }
        }
      }
    }
  `)

  const images = query.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  const [filterRegion, setFilterRegion] = React.useState<TOption>()

  const [filterDepartments, setFilterDepartments] = React.useState<
    TOption | undefined
  >(
    //@ts-ignore
    location.state ? location.state.department : undefined
  )
  const [filterSearch, setFilterSearch] = React.useState<string>('')

  const list = getVacanciesByDepartments(query)

  if (list) {
    const total = list?.vacanciesByDepartments.reduce(
      (acc, cur) => acc + cur.vacancyCount,
      0
    )

    if (filterDepartments && filterDepartments.value !== '0') {
      list.vacanciesByDepartments = list.vacanciesByDepartments.filter(
        (item) => item.id === filterDepartments.value
      )
    }

    if (filterRegion && filterRegion.value !== '0') {
      list.vacanciesByDepartments = list.vacanciesByDepartments.reduce(
        (acc: typeof list.vacanciesByDepartments, cur) => {
          let vacArr: any = []
          cur.vacancies.forEach((vacancy) => {
            if (vacancy.regions.indexOf(filterRegion.label) !== -1) {
              vacArr.push(vacancy)
            }
          })

          cur.vacancies = vacArr
          cur.vacancyCount = vacArr.length

          if (cur.vacancyCount > 0) {
            acc.push(cur)
          }

          return acc
        },
        []
      )
    }

    if (filterSearch && filterSearch.length > 0) {
      list.vacanciesByDepartments = list.vacanciesByDepartments.filter(
        (item) => {
          let res = false
          item.vacancies.forEach((vacancy) => {
            if (
              vacancy.title
                .toUpperCase()
                .indexOf(filterSearch.toUpperCase()) !== -1 ||
              vacancy.excerpt
                .toUpperCase()
                .indexOf(filterSearch.toUpperCase()) !== -1 ||
              vacancy.levels
                .map((l) => l.toUpperCase())
                .indexOf(filterSearch.toUpperCase()) !== -1
            ) {
              res = true
            }
          })

          return res
        }
      )
    }

    list.departments.unshift({
      label: 'Все команды',
      value: '0',
    })

    list.regions.unshift({
      label: 'Везде',
      value: '0',
    })

    return (
      <RootLayout>
        <Meta
          title="Карьера в IT в мобильной разработке — KODE"
          description="Начинайте карьеру в IT в KODE — крупнейшей компании мобильной разработки в России ✔ Офис в Калининграде"
        />
        <Hero isMobile={isMobile}>
          <Header images={images} vacancyCount={total} />
        </Hero>
        <HBox height={48} />
        <Form
          departmentValue={filterDepartments}
          departments={list.departments}
          regions={list.regions}
          onDepartmentChange={setFilterDepartments}
          onRegionChange={setFilterRegion}
          onSearchChange={setFilterSearch}
        />
        {isMobile ? null : <HBox height={64} />}

        <List list={list.vacanciesByDepartments} />

        <HBox height={72} />
      </RootLayout>
    )
  } else {
    return null
  }
}

export default Careers
