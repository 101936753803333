const declOfNum: (titles: [string, string, string]) => any = (function () {
  const cases = [2, 0, 1, 1, 1, 2]
  const declOfNumSubFunction = function (
    titles: [string, string, string],
    number: number
  ) {
    number = Math.abs(number)
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ]
  }
  return function (_titles: [string, string, string]) {
    if (arguments.length === 1) {
      return function (_number: number) {
        return declOfNumSubFunction(_titles, _number)
      }
    } else {
      return declOfNumSubFunction.apply(null, arguments as any)
    }
  }
})()

const formatOpenedVacancies: (number: number) => string = declOfNum([
  'открытая вакансия',
  'открытые вакансии',
  'открытых вакансий',
])

const formatVacancy: (number: number) => string = declOfNum([
  'вакансия',
  'вакансии',
  'вакансий',
])

export const decOfNums = { formatVacancy, formatOpenedVacancies }
